/* You can add global styles to this file, and also import other style files */
html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: bilo, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.font-bilo-thin {
  font-family: bilo, sans-serif;
  font-weight: 200;
  font-style: normal;
}
.font-bilo-light {
  font-family: bilo, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.font-bilo-bold {
  font-family: bilo, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.font-bilo-extrabold {
  font-family: bilo, sans-serif;
  font-weight: 800;
  font-style: normal;
}

.page-navigation {
  vertical-align: middle;
  margin: 0 10px;
  cursor: pointer;
  opacity: 0.5;
  border-radius: 100%;

  &.previous {
    transform: rotate(180deg);
  }

  &.disabled {
    opacity: 0.1;
    cursor: default;
  }

  &:hover {
    opacity: 1;
    background-color: rgba(0,0,0,0.1);
  }

  &.disabled:hover {
    opacity: 0.1;
  }

}

.pagination {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 0 100px;
  bottom: 0;
  text-align: center;

  span {
    color: #999;
    font-style: italic;
    display: inline-block;
    min-width: 70px;
    text-align: center;
  }
}

.button {
  height: 32px;
  padding: 0 10px;
  margin: 5px 10px 0 0;
  border: 1px solid #276996;
  border-radius: 3px;
  cursor: pointer;
  &-primary {
    background-color: rgba(#276996, 0.7);
    color: rgba(#FFF,1);
    &:hover {
      background-color: rgba(#276996, 1);
    }
  }
  &-secondary {
    background-color: rgba(#276996, 0.2);
    color: rgba(#FFF, 0.8);
    &:hover {
      background-color: rgba(#276996, 0.5);
    }
  }
}

.button-bar {
  display: block;
}

.pic {
  border: 20px solid rgba(#FFF, 0.3);

  &--left {
    float: left;
    margin-right: 20px;
  }

  &--margin-top {
    margin-top: -120px;
  }
}

.content {
  position: relative;
  max-width: 1024px;
  padding: 0 4vw;
  margin: 0 auto;
  color: #333;
  font-size: 18px;
  line-height: 24px;
  
  h2 {
    font-family: bilo, sans-serif;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 2px;
    font-size: 24px;
    padding: 0;
    overflow-wrap: break-word;
    text-transform: uppercase;
  }

  // p {
  //   padding: 0 20px;
  // }

  a {
    color: #333;
    text-decoration-color: #CCC;
    &:hover {
      color: #6996C3;
      text-decoration-color: rgba(#6996C3, 0.5);
    }
  }
}

.content-default {
  margin: 40px auto 0;
  font-size: 18px;

  h1 {
    font-size: 50px;
    line-height: 40px;
    letter-spacing: 10px;
  }
}

.banner {
  position: relative;
  height: 20vh;
  width: 100%;
  max-height: 250px;
  background-repeat: no-repeat;
  background-image: url(/assets/banner-5.jpg);
  background-size: 200% auto;
  background-position: center;
  overflow: hidden;
  padding-top: 100px;

  img {
    max-width: 100%;
  }

  .title {
    position: absolute;
    width: 100%;
    bottom: -2px;
  }
  h1 {
    display: block;
    max-width: 1024px;
    margin: 0 auto;
    font-size: 80px;
    color: #fff;
    letter-spacing: 15px;
    // padding: 0 4vw;
    box-sizing: border-box;
  }
}

.banner-default {
  height: 20vh;

  .title {
      bottom: 2px;

      &--pic-margin {
          margin-left: 285px;

          // h1 {
          //   margin-left: 200px;
          // }
        }
    }

    h1 {
      font-size: 60px;
      line-height: 40px;
      // margin-left: 4vw;
      // max-width: 1024px;
      // margin: 0 auto
    }
  
    .font-bilo-thin,
    .font-bilo-light,
    .font-bilo-bold,
    .font-bilo-extrabold {
      letter-spacing: 0;
    }
}

.blogs {
  padding: 0;
  margin: 0;
  list-style: none;

  h2 {
    margin-left: 0;
    margin-top: 0;
  }

  li {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    vertical-align: top;

    &:first-child {
      padding-right: 20px;
    }

    &:last-child {
      padding-left: 20px;
    }
  }
}

hr {
  margin: 40px 0;
}

.goto {
  z-index: 100;
  position: absolute;
  background-color: #FFF;
  width: 130px;
  text-align: center;
  // margin: 0 50%;
  margin-left: calc(50% - 130px);
  margin-top: -55px;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.cookie-statement {
  display: block;
  float: left;
  margin-bottom: 10px;
}

@media only screen and (max-width: 599px) {
  .banner {
    background-size: 250% 100%;
  }

  .banner-default {
    .title--pic-margin {
        bottom: 100px;
        margin-left: 0;
      }
    }
  .display-desktop {
    display: none !important;
  }
  .display-mobile {
    display: block !important;
  }

  .banner-default {
    h1 {
      font-size: 55px;
      line-height: 37px;
      margin-left: 8vw;
      max-width: calc(100% - 16vw);
      display: block;

      // span {
      //   text-overflow: ellipsis;
      //   // border: 1px solid red;
      //   display: block;
      //   height: 37px;
      //     overflow: hidden;
      // }
    }

    .font-bilo-thin,
    .font-bilo-light,
    .font-bilo-bold,
    .font-bilo-extrabold {
      display: block;
    }
  }

    .pic {
      width: calc(100vw - 40px - 16vw);
      height: auto;
    }

    .content {
      padding: 0 8vw;
    }

    .blogs {
      li {
        width: 100%;
      }

      img {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }
    }
}

@media only screen and (min-width: 600px) {

  .button-bar {
    float: right;
  }

  .display-desktop {
    display: block !important;
  }

  .display-mobile {
    display: none !important;
  }

}

@media only screen and (min-width: 601px) and (max-width: 1400px) {
  .banner {
    background-size: auto 200%;
    max-height: 250px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1111px) {
  .banner {
    h1 {
        margin-left: 4vw;
        // max-width: 1024px;
        // margin: 0 4vw
      }
    }
}
@media only screen and (min-width: 601px) and (max-width: 1400px) {
  .banner {
    background-size: auto 200%;
    max-height: 250px;
  }
}

@media only screen and (min-width: 1400px) {
  .banner {
    background-size: 100% auto;
    max-height: 350px;
  }
}


@media only screen and (min-width: 350px)  and (max-width: 499px){
  .page-navigation {
    margin: 0 20px;
  }
}

@media only screen and (min-width: 500px) {
  .page-navigation {
    margin: 0 50px;
  }
}

@media (min-width:320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width:481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width:641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width:961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {
  /* big landscape tablets, laptops, and desktops */
}

@media (min-width:1281px) {
  /* hi-res laptops and desktops */
}
